<template>
  <div class="heading" v-if="currentRouteMeta.header===true && currentScreenOptions.length>1">
    <DisplayText :screen-properties="currentScreenProperties"></DisplayText>
  </div>

  <img src="@/assets/images/FlemishLion.png" class="img_lion" alt="lion" @click="toggleText"/>
</template>

<script>
import {mapState} from "vuex";
import DisplayText from "@/components/common/DisplayText";
import store from "@/store";

export default {
  name: "Header",
  components: {DisplayText},
  computed: {
    ...mapState(['currentScreenProperties', 'currentScreenOptions']),
    currentRouteMeta() {
      return this.$route.meta;
    }
  },
  methods: {
    toggleText() {
      store.commit('toggleText');
    }
  }
}
</script>