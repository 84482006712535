import {createStore} from "vuex";
import helper from "@/services/helper";

export default createStore({
    state: {
        accessToken: "",
        currentSiteId: null,
        firstScreenId: null,
        defaultLanguage: {},
        finishedSteps: [],
        allSteps: [],
        currentScreenProperties: {},
        currentScreenOptions: [],
        currentScreenInstructions: [],
        toggleText: false,
        languageId: null,
        domaintypeId: null,
        showModal: false,
        showError: false,
        showWarning: false,
        showTimerPopup: false,
        timerPopupMessage: '',
        errorStatus: '',
        visitor: {},
        qrCode: {},
        qrCodeError: false,
        disableButtons: false
    },
    mutations: {
        addNextScreen(state, screen) {
            let step = {
                "screenId": screen.id,
                "startTime": screen.startTime,
                "endTime": screen.endTime,
                "languageId": screen.languageId,
                "flowOptions": screen.flowOptions,
                "instructions": screen.instructions,
                "screenProperties": screen.screenProperties,
                "selectedOption": screen.selectedOption
            };
            state.toggleText = false;
            state.allSteps.push(step);
            state.finishedSteps.push(screen.id);
        },
        addNextScreenToAllSteps(state, screen) {
            let step = {
                "screenId": screen.id,
                "startTime": screen.startTime,
                "endTime": screen.endTime,
                "languageId": screen.languageId,
                "flowOptions": screen.flowOptions,
                "instructions": screen.instructions,
                "screenProperties": screen.screenProperties,
                "selectedOption": screen.selectedOption
            };
            if (screen.error) {
                step.error = screen.error;
            }
            state.toggleText = false;
            state.allSteps.push(step);
        },
        getPreviousScreen(state) {
            state.toggleText = false;
            state.finishedSteps.pop();
        },
        updateScreen(state, screenData) {
            state.currentScreenProperties = screenData.screen;
            state.currentScreenOptions = screenData.informationFlow;
            state.currentScreenInstructions = screenData.instructions;
            helper.sortByKey(state.currentScreenOptions, 'sortOrder');
        },
        resetScreen(state) {
            state.currentScreenProperties = {};
            state.currentScreenOptions = [];
            state.currentScreenInstructions = {};
            state.finishedSteps = [];
            state.allSteps = [];
            state.toggleText = false;
            state.showModal = false;
            state.showError = false;
            state.errorStatus = '';
            state.languageId = null;
            state.domaintypeId = null;
            state.visitor = {};
            state.qrCode = {};
            state.qrCodeError = false;
            // state.showWarning = false;
            state.showTimerPopup = false;
            state.timerPopupMessage = '';
        },
        toggleText(state) {
            state.toggleText = !state.toggleText;
        },
        selectLanguage(state, languageId) {
            state.languageId = languageId;
        },
        setDomain(state, domaintypeId) {
            state.domaintypeId = domaintypeId;
        },
        showModal(state, value) {
            state.showModal = value;
        },
        showError(state, value) {
            state.showError = value;
        },
        showWarning(state, value) {
            state.showWarning = value;
        },
        showTimerPopup(state, value) {
            state.showTimerPopup = value;
        },
        setTimerPopupMessage(state, value) {
            state.timerPopupMessage = value
        },
        setVisitorData(state, value) {
            state.visitor = value
        },
        setqrCodeData(state, value) {
            state.qrCode = value
        }
    },
    actions: {
        getPreviousScreen(context) {
            let previousScreen = context.state.finishedSteps[context.state.finishedSteps.length - 1];
            context.commit('getPreviousScreen');
            return previousScreen;
        }
    }
});