<template>
  <div class="welcome">
    <DisplayText :screenProperties="screenProperties"></DisplayText>
  </div>

  <!--BUTTONS-->
  <div class="buttons" id="start-buttons">
    <QppButton :buttons="buttons"></QppButton>
  </div>
</template>

<script>
import store from "@/store";
import helper from "@/services/helper";
import DisplayText from "@/components/common/DisplayText";
import QppButton from "@/components/common/QppButton";

export default {
  name: 'start',
  components: {DisplayText, QppButton},
  data() {
    return {
      buttons: [],
      screenProperties: {}
    }
  },
  mounted() {
    store.commit('showModal', false);
    this.screenProperties = store.state.currentScreenProperties;
    this.buttons = store.state.currentScreenOptions;
    helper.sortByKey(this.buttons, 'sortOrder');
    if (document.readyState !== 'loading') {
      setTimeout(this.setlangAnimation, 50);
      setTimeout(this.addHandAnimation, 500);
    } else {
      document.addEventListener('DOMContentLoaded', function () {
        setTimeout(this.setlangAnimation, 50);
        setTimeout(this.addHandAnimation, 500);
      });
    }
  },
  methods: {
    setlangAnimation() {
      let firstButton = document.getElementsByClassName('lang-button');
      if (firstButton) {
        let newAnimation = firstButton[0].classList.contains('new-animation')
        let parentButton = firstButton[0].parentElement;
        parentButton.classList.add('language-animation');
        if (newAnimation) {
          parentButton.classList.add('new-animation');
        }
      }
    },
    addHandAnimation() {
      let startIcon = document.getElementsByClassName('start-button');
      const elem = document.createElement('i');
      if (startIcon) {
        let parentButton = startIcon[0].parentElement;
        elem.classList.add("fa", "fa-hand-point-right", 'fa-3x', 'white', "hand-pointer-start");
        if (parentButton)
          parentButton.prepend(elem)
      }
    }
  }
}
</script>
