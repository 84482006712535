<template>
  <div v-if="!isLoaded">
    <i class="fa-3x fas fa-spinner fa-pulse"></i>
  </div>

  <div v-else>
    <div class="instruction">
      <DisplayText :screenProperties="screenProperties"></DisplayText>
    </div>

    <div class="manual-entry">
      <div class="input-field">
        <label for="manual_input">{{ names }}</label>
        <input :value="input"
               id="manual_input"
               class="input"
               @input="onInputChange"
               placeholder=""
               autocomplete="off"
               autofocus="autofocus"
               type="text" title="Name">
      </div>
      <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input"/>
    </div>
    <div class="input-form-submit">
      <QppButton :buttons="buttons" @callback="submit()" :loaded="inputSaved"></QppButton>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import DisplayText from "@/components/common/DisplayText";
import QppButton from "@/components/common/QppButton";
import screenApiService from "@/services/screenApiService";
import SimpleKeyboard from "@/components/common/SimpleKeyboard";
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";


export default {
  name: 'INPUT_FORM',
  components: {DisplayText, QppButton, SimpleKeyboard},
  data() {
    return {
      screenProperties: {},
      buttons: [],
      input: "",
      inputSaved: false,
      names: '',
      visitor: undefined,
      isLoaded: false
    }
  },
  computed: mapState(['toggleText']),
  watch: {
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id) {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, 'names');
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, 'names');
        }
      }
    }
  },
  mounted() {
    this.buttons = store.state.currentScreenOptions;
    this.screenProperties = store.state.currentScreenProperties;
    this.getTranslation(store.state.languageId, 'names');
    this.visitor = store.state.visitor;
    screenApiService.showPopup();

    if (this.visitor && this.visitor.DATA_OBJECT === 'BeidCard' && this.buttons.length > 0) {
      let button = {id: this.buttons[0].id};
      let langId = store.state.defaultLanguage.id;
      let domaintypeId = null;
      let checkEer;
      let checkEid;
      let screenshot = {
        selectedButton: button,
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        instructions: store.state.currentScreenInstructions,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId
      };

      if (Object.prototype.hasOwnProperty.call(this.buttons[0], "defaultPayload")) {
        let defaultPayload = JSON.parse(this.buttons[0]['defaultPayload']);
        domaintypeId = defaultPayload && defaultPayload['domaintypeId'] ? defaultPayload['domaintypeId'] : store.state.domaintypeId;
        langId = defaultPayload && defaultPayload['languageId'] ? defaultPayload['languageId'] : store.state.languageId;
        checkEer = defaultPayload && defaultPayload['checkEer'] ? defaultPayload['checkEer'] : null;
        checkEid = defaultPayload && defaultPayload['checkEid'] ? defaultPayload['checkEid'] : null;
        store.commit('setDomain', domaintypeId);
        store.commit('selectLanguage', langId);
        screenshot.languageId = langId;
        screenshot.domaintypeId = domaintypeId;
      }

      if(checkEid !== null) {
        screenshot['checkEid'] = checkEid
      }

      if(checkEer !== null) {
        screenshot['checkEer'] = checkEer
      }

      screenService.goToNextScreen(screenshot);
    } else {
      this.isLoaded = true;
    }
    if (document.readyState !== 'loading') {
      setTimeout(() => {
        document.getElementById("manual_input").focus();
      }, 300);
    }
  },
  beforeUnmount() {
    screenApiService.resetPopup();
  },
  methods: {
    getTranslation(lang = store.state.languageId, key) {
      var app = this;
      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function (er) {
          console.log("No translation found for fe." + key + " in language " + lang);
          console.log(er);
          app[key] ="-";
        })
      }
    },
    onChange(input) {
      this.input = input;
    },
    onKeyPress() {
      // console.log("button", button);
    },
    onInputChange(input) {
      this.input = input.target.value;
    },
    submit() {
      if (this.visitor && this.visitor.DATA_OBJECT === 'BeidCard') {
        console.log("already used eID")
      } else {
        this.visitor = {
          SURNAME: this.input
        }
        store.commit('setVisitorData', this.visitor);
      }
      this.inputSaved = true;
    }
  }
}
</script>
