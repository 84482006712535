<template>
  <!--INSTRUCTION-->
  <div class="instruction" v-if="loaded && instructions">
    <div v-if="instructions.appointmentText" class="appointment-text">
      <span v-if="toggleText" v-html="instructions.appointmentTextHelp"></span>
      <span v-else v-html="instructions.appointmentText"></span>
      <br>
      <br>
      <hr>
      <br>
    </div>

    <DisplayText :screenProperties="screenProperties" :isInstruction="true" :instructions="instructions"></DisplayText>

    <br>

    <!--Arrow-->
    <span class="route">{{ instructions.route }}</span>
    <svg v-if="instructions && instructions.arrowDegrees !== null"
         id="directionArrow"
         width="10vw"
         height="10vw"
         viewBox="0 0 24 24"
         :fill="instructions.routeColor" stroke="none"
         :transform="'rotate(' + instructions.arrowDegrees + ')'">
      <path
          d="M 7,11 H 1 L 12,0 23,11 H 17 V 24 H 7 Z"/>
    </svg>
  </div>


  <div class="buttons" v-show="close!==''">
    <button class="btn buttons pointer close" :disabled="disableButtons" @click="closeInstruction({'id':'closeInstruction'})">{{
        close
      }}
    </button>
    <i class="fa fa-hand-point-right fa-3x hand-pointer-instruction"></i>
  </div>

  <div class="ticket-warning"
       v-if="instructions && instructions.ticket && instructions.ticket.data && ticketWarning!==''">
    {{ ticketWarning }}
    <br>
    <svg id="directionArrow"
         width="10vw"
         height="10vw"
         viewBox="0 0 24 24"
         fill="#fff" stroke="none"
         transform="rotate(130)">
      <path
          d="M 7,11 H 1 L 12,0 23,11 H 17 V 24 H 7 Z"/>
    </svg>
  </div>
</template>

<script>
import store from "@/store";
import screenService from "@/services/screenApiService";
import DisplayText from "@/components/common/DisplayText";
import zijderoute from "@/services/zijderoute";
import {mapState} from "vuex";
const sound = require("@/assets/eID-vergeten.mp3");

export default {
  name: 'Instruction',
  components: {DisplayText},
  data() {
    return {
      instructions: {},
      screenProperties: {},
      loaded: false,
      close: '',
      ticketWarning: '',
      cardChecked: false,
      idleTimeoutForCountdown: null
    }
  },
  computed: mapState(['toggleText', 'disableButtons', 'showTimerPopup']),
  watch: {
    showTimerPopup(newValue) {
      if (newValue === false) {
        // print ticket once timer popup disappears
        this.printTicket();
      }
    }
  },
  mounted() {
    this.screenProperties = store.state.currentScreenProperties;
    this.instructions = store.state.currentScreenInstructions;
    this.getTranslation(store.state.languageId, 'close');
    this.getTranslation(store.state.languageId, 'ticketWarning');
    this.loaded = true;
    if (!this.showTimerPopup) {
      // print ticket only if there is no timer popup
      this.printTicket();
    }
    zijderoute.onLoad();

    // Clears the existing timeout
    if (this.idleTimeoutForCountdown) clearTimeout(this.idleTimeoutForCountdown);
    // Set timeout to redirect after timeOut seconds
    this.idleTimeoutForCountdown = setTimeout(() => {
      this.closeInstruction({'id':'automaticRedirect'})
    }, this.screenProperties.timeOut * 1000);
  },
  beforeUnmount() {
    // Clears the existing timeout
    if (this.idleTimeoutForCountdown) clearTimeout(this.idleTimeoutForCountdown);
    zijderoute.offLoad();
  },
  methods: {
    getTranslation(lang = store.state.languageId, key) {
      var app = this;
      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function (err) {
          console.log("No translation found for fe." + key + " in language " + lang);
          console.log(err);
          app[key] = "-";
        })
      }
    },
    printTicket() {
      // TO DO: check printer status first
      if (this.instructions && this.instructions.ticket && this.instructions.ticket.data) {
        let imgUrl = this.instructions.ticket.data;
        console.log("Printing image: " + imgUrl);
        zijderoute.printImage(imgUrl, (msg) => {
          console.log(msg);
        });
      }
    },
    closeInstruction(button) {
      store.state.disableButtons = true;
      this.cardChecked = false;
      this.checkCard(button);
      setTimeout(() => {
        if (this.cardChecked === false) {
          this.exitPage(button);
        }
      }, 10000);
    },

    checkCard(button) {
      zijderoute.checkCard((msg) => {
        this.cardChecked = true;
        if (msg.success === true) {
          let cardPresent = msg.message.CARD_PRESENT;
          if (cardPresent === true) {
            console.log("card present")
            let audio = new Audio(sound);
            audio.play();
            store.commit('showWarning', true);
            setTimeout(() => {
              if (store.state.showWarning === true)
                store.commit('showWarning', false);
            }, 10000);
          } else {
            console.log("card not present")
          }
          this.exitPage(button);
        } else {
          console.log("Problems with checking card")
          this.exitPage(button);
        }
      });
    },

    exitPage(button) {
      let screenshot = {
        selectedButton: button,
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        instructions: store.state.currentScreenInstructions,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId,
      };
      screenService.endFlow(screenshot);
    }
  }
}
</script>
