<template>
  <button class="btn" v-for="button in buttons" :class="{'many-buttons' : buttons.length > 4}" :key="button.id"
          @click="goToNextScreen(button)"
          :disabled="disableButtons">
    <span v-if="toggleText" v-html="button.helpText" :class="button.icon? 'w-9' : ''"></span>
    <span v-else v-html="button.text" :class="button.icon? 'w-9' : ''"></span>
    <i class='fas right vdab-lightblue move w3-margin-left' v-if="button.icon" :class="button.icon"/>
  </button>
</template>

<script>
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";
import store from "@/store";

export default {
  name: "Button",
  props: {'buttons': undefined, 'loaded': undefined},
  emits: ["callback"],
  data() {
    return {
      selectedButton: undefined
    }
  },
  computed: mapState(['toggleText', 'disableButtons']),
  watch: {
    loaded(newValue) {
      if (newValue === true) {
        this.goToNextScreen(this.buttons[0]);
      }
    }
  },
  methods: {
    goToNextScreen(button) {
      store.state.disableButtons = true;
      if (this.loaded === undefined || this.loaded === true) {
        let langId = store.state.defaultLanguage.id;
        let domaintypeId = null;
        let checkEer;
        let checkEid;
        let startNow = false;
        let screenshot = {
          selectedButton: button,
          flowOptions: store.state.currentScreenOptions,
          screenProperties: store.state.currentScreenProperties,
          instructions: store.state.currentScreenInstructions,
          languageId: store.state.languageId,
          domaintypeId: store.state.domaintypeId
        };

        if (Object.prototype.hasOwnProperty.call(button, "defaultPayload")) {
          let defaultPayload = JSON.parse(button['defaultPayload']);
          domaintypeId = defaultPayload && defaultPayload['domaintypeId'] ? defaultPayload['domaintypeId'] : store.state.domaintypeId;
          langId = defaultPayload && defaultPayload['languageId'] ? defaultPayload['languageId'] : store.state.languageId;
          checkEer = defaultPayload && defaultPayload['checkEer'] ? defaultPayload['checkEer'] : null;
          checkEid = defaultPayload && defaultPayload['checkEid'] ? defaultPayload['checkEid'] : null;
          store.commit('setDomain', domaintypeId);
          store.commit('selectLanguage', langId);
          screenshot.languageId = langId;
          screenshot.domaintypeId = domaintypeId;
        }

        if(checkEid !== null) {
          screenshot['checkEid'] = checkEid
        }

        if(checkEer !== null) {
          screenshot['checkEer'] = checkEer
        }

        if (this.$route.name === 'START') {
          startNow = true;
        }
        screenService.goToNextScreen(screenshot, startNow);
      } else {
        this.$emit('callback');
        this.selectedButton = button;
      }
    }
  }
}
</script>