<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title vdab-darkblue"> {{ popup }}</h1>
            </div>
            <div class="modal-body">
              <input type="button" class="btn blue-btn btn-success btn-xs" :value="popupButton"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from "@/store";
import screenService from "@/services/screenApiService";

export default {
  name: "Popup",
  data() {
    return {
      popup: '',
      popupButton: ''
    }
  },
  mounted() {
    this.getTranslation(store.state.languageId, 'popup');
    this.getTranslation(store.state.languageId, 'popupButton');
  },
  methods: {
    getTranslation(lang = store.state.languageId, key) {
      var app = this;
      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function (error) {
          console.log("No translation found for fe." + key + " in language " + lang);
          console.log(error);
          app[key] ="-";
          //app.getTranslation(store.state.defaultLanguage.id, key);
        })
      }
    },
  }

}
</script>