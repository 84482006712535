<template>
  <transition name="modal">
    <div class="modal-mask" id="error-modal">
      <div class="modal-wrapper">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title vdab-orange"> ERROR {{ errorStatus }} : {{ popupError }}</h2>
            </div>
            <div class="modal-body">
              <input type="button" class="btn blue-btn btn-success btn-xs" :value="popupErrorButton" @click="close"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from "@/store";
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";

export default {
  name: "Error",
  data() {
    return {
      popupError: 'Geen internetverbinding.\n' +
          '\n' +
          'Gelieve een systeem administrator te contacteren.',
      popupErrorButton: 'Sluiten'
    }
  },
  emits: ['closeError'],
  computed: mapState(['errorStatus']),
  mounted() {
    this.getTranslation(store.state.languageId, 'popupError');
    this.getTranslation(store.state.languageId, 'popupErrorButton');
  },
  methods: {
    getTranslation(lang = store.state.languageId, key) {
      var app = this;
      if (lang === null) {
        lang = store.state.defaultLanguage.id
        if (store.state.errorStatus === 401) {
          app.popupError = "Er is een probleem met de computer en deze moet opnieuw worden opgestart. \n" +
              "Gelieve een systeem administrator te contacteren."
          app.popupErrorButton = "Sluiten"
        }
      }

      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function (er) {
          if (er.response.status === 401) {
            app.popupError = "Er is een probleem met de computer en deze moet opnieuw worden opgestart. \n" +
                "Gelieve een systeem administrator te contacteren."
            app.popupErrorButton = "Sluiten"
          } else {
            console.log("No translation found for fe." + key + " in language " + lang);
            console.log(er);
            app[key] ="-";
            //app.getTranslation(store.state.defaultLanguage.id, key);
          }
        })
      }
    },
    close() {
      this.$emit('closeError')
    }
  }

}
</script>