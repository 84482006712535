module.exports = {
    "development": {
        host: "http://localhost:7000",
        url: "http://localhost:7000"
    },
    "vdabdev": {
        host: "https://qpp.dev.vdab.partheas.net",
        url: "https://qpp.dev.vdab.partheas.net"
    },
    "uat": {
        host: "https://qpp.uat.vdab.partheas.net",
        url: "https://qpp.uat.vdab.partheas.net"
    },
    "production": {
        host: "https://qpp.vdab.partheas.net",
        url: "https://qpp.vdab.partheas.net"
    },
    "demo":{
        host: "https://api.demo.q.partheas.net",
        url: "https://api.demo.q.partheas.net"
    },
    "qartsdev":{
        host: "http://dev.api.qarts.partheas.net",
        url: "http://dev.api.qarts.partheas.net"
    },
    "qartsdemo":{
        host: "http://demo.api.qarts.partheas.net",
        url: "http://demo.api.qarts.partheas.net"
    }
}