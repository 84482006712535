<template>
  <div class="launch">
    <div class="launch-content">
      <img src="@/assets/images/VDAB_logo_donkerblauw_RGB.jpg" alt="VDAB" class="launch-image w3-margin-bottom">
      <div id="oops-message" v-if="error">
        <h1 class="vdab-green">
          <i class="fas fa-exclamation-triangle"></i>
          Oooops.. What's wrong?
        </h1><br><br>
        <div v-if="error === 'urlIssue'">
          <h1 class="vdab-darkblue">Please make sure that your launch URL is correct! </h1>
        </div>
        <div v-else-if="error === 'idNotFound'">
          <h1 class="vdab-darkblue">Site ID for this Office ID not found in the database! </h1>
        </div>
        <div v-else-if="error === 'authorizationIssue'">
          <h1 class="vdab-darkblue">Please make sure that the authorization details are correct! </h1>
        </div>
        <div v-else>
          <h1 class="vdab-darkblue">An error occurred while launching! </h1>
        </div>
        <br><br>
        <h3 class="vdab-green">Please contact your administrator.</h3><br>
      </div>

      <div v-else class="fa-10x vdab-green">
        <h1 class=""> Launching... </h1>
        <i class="fas fa-cog fa-spin"></i>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Launch",
  computed: {
    error: function () {
      return this.$route.params.error;
    }
  }
}
</script>

<style scoped>
.launch {
  background-color: white;
  height: 100%;
  width: 100%;
  position: fixed;
}

.launch-content {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.launch-image {
  height: 100px
}

</style>