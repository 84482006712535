<template>
	<div class="welcome">
		<DisplayText :screenProperties="screenProperties"></DisplayText>
	</div>

	<!--BUTTONS-->
	<div class="buttons" id="start-buttons">
		<QppButton :buttons="buttons"></QppButton>
	</div>

	<div v-if="eidButton" class="eid-button w3-hide">
		<QppButton :buttons="eidButton" :loaded="cardRead"></QppButton>
	</div>

	<div v-if="qrButton" class="qr-button w3-hide">
		<QppButton :buttons="qrButton" :loaded="qrRead"></QppButton>
	</div>

</template>

<script>
import store from "@/store";
import helper from "@/services/helper";
import DisplayText from "@/components/common/DisplayText";
import QppButton from "@/components/common/QppButton";
import screenApiService from "@/services/screenApiService";
import zijdeRoute from "@/services/zijderoute"
import {mapState} from "vuex";

export default {
	name: 'START_QR_EID',
	components: {DisplayText, QppButton},
	data() {
		return {
			buttons: [],
			screenProperties: {},
			eidButton: [],
			qrButton: [],
			cardRead: false,
			qrRead: false,
			cardNotFound: '',
			startTime: new Date(),
			cardChecked: false,
			cardReadSuccess: false,
			go: '',
			cardCheckTimer: null,
			cardReadTimer: null
		}
	},
	computed: mapState(['toggleText','showError']),
	watch: {
		toggleText(newValue) {
			if (store.state.languageId !== store.state.defaultLanguage.id && this.cardNotFound !== '') {
				if (newValue === true) {
					this.getTranslation(store.state.defaultLanguage.id, 'cardNotFound');
					this.getTranslation(store.state.defaultLanguage.id, 'go');
				} else if (newValue === false) {
					this.getTranslation(store.state.languageId, 'cardNotFound');
					this.getTranslation(store.state.languageId, 'go');
				}
			}
		},
		showError(newValue) {
			if (newValue === false) {
				this.checkCard();
			}
		}
	},
	mounted() {
		const app = this;
		console.log("start_eid screen mounted start")
		store.commit('showModal', false);
		this.screenProperties = store.state.currentScreenProperties;
		let buttons = store.state.currentScreenOptions;
		let eidButton = [buttons[0]];
		let qrButton = [buttons[1]];
		this.eidButton = eidButton;
		this.qrButton = qrButton;
		buttons.splice(0, 2);
		this.buttons = buttons;

		this.checkInput(); //check QR read input

		//check Eid read input
		zijdeRoute.onLoad();
		this.cardChecked = false;
		this.cardCheckTimer = setTimeout(() => {app.checkCard();},10000)
		helper.sortByKey(this.buttons, 'sortOrder');
		if (document.readyState !== 'loading') {
			setTimeout(this.setlangAnimation, 50);
			setTimeout(this.addHandAnimation, 500);
		} else {
			document.addEventListener('DOMContentLoaded', function () {
				setTimeout(this.setlangAnimation, 50);
				setTimeout(this.addHandAnimation, 500);
			});
		}
	},
	beforeUnmount() {
		screenApiService.resetPopup();
		zijdeRoute.offLoad();
		store.commit('showError', false);
		store.state.errorStatus = '';
		clearTimeout(this.cardCheckTimer);
		clearTimeout(this.cardReadTimer);
	},
	methods: {
		setlangAnimation() {
			let firstButton = document.getElementsByClassName('lang-button');
			if (firstButton && firstButton.length > 0) {
				let newAnimation = firstButton[0].classList.contains('new-animation')
				let parentButton = firstButton[0].parentElement;
				parentButton.classList.add('language-animation');
				if (newAnimation) {
					parentButton.classList.add('new-animation');
				}
			}
		},
		addHandAnimation() {
			let startIcon = document.getElementsByClassName('start-button');
			const elem = document.createElement('i');
			if (startIcon && startIcon.length > 0) {
				let parentButton = startIcon[0].parentElement;
				elem.classList.add("fa", "fa-hand-point-right", 'fa-3x', 'white', "hand-pointer-start");
				if (parentButton)
					parentButton.prepend(elem)
			}
		},
		checkCard() {
			console.log("checkCard called");
			console.log("starting zijderoute checkCard");
			zijdeRoute.checkCard((msg) => {
				this.cardChecked = true;
				console.log("zijderoute return:");
				console.log(msg);
				if (msg.success === true) {
					let cardPresent = msg.message.CARD_PRESENT;
					if (cardPresent === true) {
						console.log("card present")
						this.cardReadSuccess = false;
						this.readCard();
						this.cardReadTimer = setTimeout(() => {
							if (this.cardReadSuccess === false) {
								store.commit('showError', true);
								store.state.errorStatus = 'READ_CARD_FAILED';
							}
						}, 20000);
					} else {
						console.log("card not present")
						this.checkCard()
					}
				} else {
					console.log("Problems with checking card present")
					store.commit('showError', true);
					store.state.errorStatus = 'CHECK_CARD_FAILED';
				}
			});
		},
		readCard() {
			// document.querySelector("button").disabled = true;
			zijdeRoute.readCard((msg) => {
				if (msg.success === true) {
					this.cardReadSuccess = true;
					this.log(msg);
					this.cardRead = true;
					this.cardNotFound = '';
					this.go = '';
				}
			});
		},

		log(message) {
			console.log("LOGGING ON KIOSK PAGE %o", message);
			store.commit('setVisitorData', message.message);
		},

		//QR read functions
		checkInput() {
			let typedString = "";
			let app = this;
			document.addEventListener("keypress", function (e) {
				if (e.key === 'Enter' || e.keyCode === 13) {
					// on ENTER
					e.preventDefault();
					app.scanQR(typedString);
					typedString = "";
				} else typedString += e.key;
			});
		},
		scanQR(qrcode) {
			store.state.qrCodeError = false
			console.log("Scanned code: " + qrcode);
			store.commit('setqrCodeData', qrcode);
			this.qrRead = true;
		}
	}
}
</script>
