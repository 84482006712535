<template>
  <!--INSTRUCTION-->
  <div class="instruction">
    <DisplayText :screenProperties="screenProperties"></DisplayText>
  </div>

  <div class="scan-media">
    <img src="@/assets/images/qrRead.gif" alt="scan" id="scan-image">
  </div>

  <div class="vdab-orange" id="card_read_message">
    <i class="fa-3x fas fa-spinner fa-pulse w3-margin-bottom" id="processing"></i><br>
    <div class="w3-margin-top" v-if="qrCodeError">{{ qrNotFound }}</div>
  </div>

  <div class="w3-hide">
    <QppButton :buttons="buttons" :loaded="qrRead"></QppButton>
  </div>

</template>

<script>
import store from "@/store";
import DisplayText from "@/components/common/DisplayText";
import QppButton from "@/components/common/QppButton";
import screenApiService from "@/services/screenApiService";
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";


export default {
  name: 'QR_READ',
  components: {DisplayText, QppButton},
  data() {
    return {
      screenProperties: {},
      qrNotFound: '',
      qrRead: false,
      buttons: []
    }
  },
  mounted() {
    this.buttons = store.state.currentScreenOptions;
    this.screenProperties = store.state.currentScreenProperties;
    this.checkInput();
    screenApiService.showPopup();
    store.state.qrCodeError = false
  },
  computed: mapState(['toggleText', 'qrCodeError']),
  watch: {
    qrCodeError(newValue) {
      if (newValue === true) {
        this.getTranslation(store.state.languageId, 'qrNotFound');
        this.qrRead = false;
      }
    },
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id && this.qrNotFound !== '') {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, 'qrNotFound');
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, 'qrNotFound');
        }
      }
    }
  },
  beforeUnmount() {
    screenApiService.resetPopup();
  },
  methods: {
    checkInput() {
      var typedString = "";
      var app = this;
      document.addEventListener("keypress", function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
          // on ENTER
          e.preventDefault();
          app.scanQR(typedString);
          typedString = "";
        } else typedString += e.key;
      });
    },
    scanQR(qrcode) {
      store.state.qrCodeError = false
      console.log("Scanned code: " + qrcode);
      store.commit('setqrCodeData', qrcode);
      this.qrRead = true;
    },
    getTranslation(lang = store.state.languageId, key) {
      var app = this;
      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function (er) {
          console.log("No translation found for fe." + key + " in language " + lang);
          console.log(er);
          app[key] ="-";
        })
      }
    },
  }
}
</script>
