<template>
  <transition name="modal">
    <div class="modal-mask" id="error-modal">
      <div class="modal-wrapper">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title vdab-orange"> {{ timerPopupMessage }}</h2>
            </div>
            <div class="modal-body">
              <input type="button" class="btn blue-btn btn-success btn-xs" :value="timerPopupButton" @click="close"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TimerPopup",
  data() {
    return {
      timerPopupButton: 'OK'
    }
  },
  emits: ['closeTimerPopup'],
  computed: mapState(['timerPopupMessage']),
  methods: {
    close() {
      this.$emit('closeTimerPopup')
    }
  }

}
</script>