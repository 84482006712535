import store from "@/store";

let transactionId;
let transactionCallback = {};
let boundReplyFunction;


export default {
    startTransaction() {
        transactionId = (new Date()).getTime();
        return transactionId;
    },

    endTransaction() {
        transactionId = undefined;
        transactionCallback = {};
    },

    readCard(callback) {
        this.sendCommand('read', {}, callback);
    }
    ,
    checkCard(callback) {
        this.sendCommand('check', {}, callback);
    },

    print(data, callback) {
        this.sendCommand('print', data, callback);
    },

    getImageData(imageUrl, callback) {
        var xhr = new XMLHttpRequest();
        let accessToken = store.state.accessToken;
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                var imgD = reader.result;
                if (imgD) {
                    imgD = imgD.split(",")[1];
                }
                callback(imgD);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', imageUrl);
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}` );
        xhr.responseType = 'blob';
        xhr.send();
    },

    printImage(imageUrl, callback) {
        this.getImageData(imageUrl, (imageData) => {

            var data = {
                'templateId': 'image',
                'image': imageData
            };

            console.log("Printing Image: " + imageData);

            this.print(data, callback);
        });
    },

    getPrinterStatus(callback) {
        this.sendCommand('status', {}, callback);
    },

    sendCommand(command, payload, callback) {
        this.startTransaction();
        transactionCallback[transactionId] = callback;

        var request = {
            'command': command,
            'transactionId': transactionId,
            'id': 'com.partheas.qpp.kiosk.zijderoute',
            'type': 'request',
            ...payload
        };

        console.log(new Date() + " - Sending message %o", request);
        window.postMessage(request);
    },


    onReply(message) {
        if (message.id !== 'com.partheas.qpp.kiosk.zijderoute' || message.type !== 'response') {
            console.log("Received a message from unknown source/type: %o", message);
            return;
        }


        if (message.transactionId !== transactionId) {
            console.log("Received a message response with transactionId: %s, but current transactionId is: %s", message.transactionId, transactionId);
            return;
        }

        console.log(new Date() + " - For transaction: %s we received response: %o", transactionId, message);

        let callback = transactionCallback[transactionId];
        this.endTransaction();
        callback(message.response);
    },

    onLoad() {
        boundReplyFunction = function (event) {
            this.onReply(event.data);
        }.bind(this)
        window.addEventListener("message", boundReplyFunction);
    },

    offLoad() {
        window.removeEventListener("message", boundReplyFunction);
        boundReplyFunction = undefined
    }

}