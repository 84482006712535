<template>
  <div class="instruction line-height-normal">
    <DisplayText :screenProperties="screenProperties"></DisplayText>
  </div>

  <div class="scan-media">
    <img src="@/assets/images/eIDRead.gif" alt="scan" id="scan-image">
  </div>

  <div class="vdab-orange" id="card_read_message">
    <i class="fa-3x fas fa-spinner fa-pulse" id="processing"></i>
    {{ cardNotFound }}
    <button class="btn" id="tryAgain" @click="recheck" v-show="go!==''"> {{ go }}</button>
  </div>
  <div class="eid-button w3-hide">
    <QppButton :buttons="buttons" :loaded="cardRead"></QppButton>
  </div>
</template>

<script>
import store from "@/store";
import DisplayText from "@/components/common/DisplayText";
import QppButton from "@/components/common/QppButton";
import screenApiService from "@/services/screenApiService";
import zijdeRoute from "@/services/zijderoute"
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";


export default {
  name: 'EID_READ',
  components: {DisplayText, QppButton},
  data() {
    return {
      screenProperties: {},
      buttons: [],
      cardRead: false,
      cardNotFound: '',
      startTime: new Date(),
      cardChecked: false,
      cardReadSuccess: false,
      go: '',
      cardCheckTimer: null,
      cardRecheckTimer: null,
      cardReadTimer: null
    }
  },
  computed: mapState(['toggleText']),
  watch: {
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id && this.cardNotFound !== '') {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, 'cardNotFound');
          this.getTranslation(store.state.defaultLanguage.id, 'go');
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, 'cardNotFound');
          this.getTranslation(store.state.languageId, 'go');
        }
      }
    }
  },
  mounted() {
    this.buttons = store.state.currentScreenOptions;
    this.screenProperties = store.state.currentScreenProperties;
    document.getElementById('processing').style.display = 'inline-block';
    document.getElementById('tryAgain').style.display = 'none';
    screenApiService.showPopup();
    zijdeRoute.onLoad();
    this.cardChecked = false;
    this.checkCard();
    this.cardCheckTimer = setTimeout(() => {
      if (this.cardChecked === false) {
        store.commit('showError', true);
        store.state.errorStatus = 'CHECK_CARD_FAILED';
      }
    }, 10000);
  },
  beforeUnmount() {
    screenApiService.resetPopup();
    zijdeRoute.offLoad();
    store.commit('showError', false);
    store.state.errorStatus = '';
    clearTimeout(this.cardCheckTimer);
    clearTimeout(this.cardRecheckTimer);
    clearTimeout(this.cardReadTimer);
  },
  methods: {
    getTranslation(lang = store.state.languageId, key) {
      var app = this;
      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function (er) {
          console.log("No translation found for fe." + key + " in language " + lang);
          console.log(er);
          app[key] = "-";
        })
      }
    },
    recheck() {
      clearTimeout(this.cardReadTimer);
      this.reset();
      this.startTime = Date.now();
      this.cardChecked = false;
      this.checkCard();
      this.cardRecheckTimer = setTimeout(() => {
        if (this.cardChecked === false) {
          store.commit('showError', true);
          store.state.errorStatus = 'CHECK_CARD_FAILED';
        }
      }, 15000);
    },
    checkCard() {
      let timeElapsed = (Date.now() - this.startTime) / 1000; //seconds
      if (timeElapsed >= 30) {
        document.getElementById('processing').style.display = 'none';
        document.getElementById('tryAgain').style.display = 'block';
        this.getTranslation(store.state.languageId, 'cardNotFound');
        this.getTranslation(store.state.languageId, 'go');
      } else {
        zijdeRoute.checkCard((msg) => {
          this.cardChecked = true;
          if (msg.success === true) {
            let cardPresent = msg.message.CARD_PRESENT;
            if (cardPresent === true) {
              console.log("card present")
              this.cardReadSuccess = false;
              this.readCard();
              this.cardReadTimer = setTimeout(() => {
                if (this.cardReadSuccess === false) {
                  store.commit('showError', true);
                  store.state.errorStatus = 'READ_CARD_FAILED';
                }
              }, 20000);
            } else {
              console.log("card not present")
              this.checkCard()
            }
          } else {
            console.log("Problems with checking card present")
            store.commit('showError', true);
            store.state.errorStatus = 'CHECK_CARD_FAILED';
          }
        });
      }
    },
    readCard() {
      this.reset();
      // document.querySelector("button").disabled = true;
      zijdeRoute.readCard((msg) => {
        if (msg.success === true) {
          this.cardReadSuccess = true;
          this.log(msg);
          this.cardRead = true;
          this.cardNotFound = '';
          this.go = '';
        } else {
          // document.querySelector("button").disabled = false;
          clearTimeout(this.cardReadTimer);
          document.getElementById('processing').style.display = 'none';
          document.getElementById('tryAgain').style.display = 'block';
          this.getTranslation(store.state.languageId, 'cardNotFound');
          this.getTranslation(store.state.languageId, 'go');
        }
      });
    },

    log(message) {
      console.log("LOGGING ON KIOSK PAGE %o", message);
      store.commit('setVisitorData', message.message);
    },

    reset() {
      this.cardNotFound = "";
      this.go = "";
      document.getElementById('processing').style.display = 'inline-block';
      document.getElementById('tryAgain').style.display = 'none';
    }

  }
}
</script>
