import {createApp} from 'vue';
import App from './App.vue';
import router from "./router";
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from "@/store";
import screenApiService from "@/services/screenApiService";

const app = createApp(App);

app.use(VueAxios, axios);
app.use(router);
app.use(store);
app.mount('#app');

router.beforeEach(function (to, from, next) {
    if (to.path === "/launch") {
        next();
        const query = to.query;
        const officeId = query.officeSiteId;
        const username = query.username;
        const password = query.password;
        const authURL = "/auth/client"
        const payload = {
            "username": username,
            "password": password
        }
        let accessToken = ""
        // call Auth API
        screenApiService.post(authURL, payload).then(function (res) {
            accessToken = res.data.access_token;
            localStorage.setItem("qppAccessToken", accessToken)
            screenApiService.getSiteId(officeId).then(function (res) {
                localStorage.setItem("qppSiteId", res.data.id)
                screenApiService.getStartScreen();
            }).catch(function (er) {
                let status = er.response.status
                console.log(status)
                if (status) {
                    if (status === 404) {
                        console.log(`Site not found for office ID ${officeId}`);
                        router.push({path: "/launch/idNotFound"})
                    } else if (status === 400) {
                        console.log(`URL parameters are not right.`);
                        router.push({path: "/launch/urlIssue"})
                    } else if (status === 401) {
                        console.log(`Authorization details are not correct.`);
                        router.push({path: "/launch/authorizationIssue"})
                    } else {
                        console.log(`Error on launch`);
                        router.push({path: "/launch/error"})
                    }
                }
            })
        }).catch(function (er) {
            let status = er.response.status
            if (status === 400) {
                console.log(`URL parameters are not right.`);
                router.push({path: "/launch/urlIssue"})
            } else {
                console.log(`Authorization details are not correct.`);
                router.push({path: "/launch/error"})
            }
        })


    } else {
        next();
    }
})
