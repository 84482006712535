import store from "@/store";
import router from "@/router";
import axios from "axios";
import apiConfig from "../apiConfig";

axios.defaults.withCredentials = true;
let currentApiConfig = apiConfig[process.env.NODE_ENV];
let baseURL = currentApiConfig["url"] + "/api";

let nextAPI = "/sites/:siteId/screens/:screenId/next";
let getScreenAPI = "/sites/:siteId/screens/";
let languagesAPI = "/sites/:siteId/languages";
let endAPI = "/sites/:siteId/screens/end";
let translationAPI = "/sites/:siteId/languages/";
let defaultLanguageAPI = "/sites/:siteId/languages/default";
let siteAPI = "/sites/officeSiteId/:officeSiteId";

let idleDurationForCountdown = 10;
let idleTimeoutPopup; // variable to hold the timeout of popup
let boundReset = undefined;

let currentStep = {
    id: null,
    startTime: null,
    endTime: null,
    selectedOption: null,
    languageId: null,
    domaintypeId: null,
    flowOptions: [],
    screenProperties: {},
    instructions: {}
};
const timerSound = require("@/assets/closing_alert.mp3");

export default {
    getSiteId(officeId) {
        return this.get(siteAPI, {officeSiteId: officeId});
    },

    getLanguages() {
        return this.get(languagesAPI);
    },

    resetFlow(screenshot) {
        this.setStepData(Date.now(), screenshot);
        store.commit('addNextScreen', currentStep);
        let trailFlow = {
            "languageId": store.state.languageId,
            "domaintypeId": store.state.domaintypeId,
            "steps": store.state.allSteps
        }

        this.post(endAPI, trailFlow).then(() => {
            store.commit('resetScreen');
            this.getStartScreen();
        }).catch((er) => {
            console.log(er);
            store.commit('showError', true);
            if (er.response) {
                store.state.errorStatus = er.response.status;
            }
        });
    },

    endFlow(button) {
        this.resetFlow(button);
    },

    getScreen(screenId, languageId) {
        let url = getScreenAPI + screenId;
        let app = this;
        if (screenId === store.state.firstScreenId && store.state.firstScreenId !== null) {
            languageId = store.state.defaultLanguage.id;
            store.commit('selectLanguage', languageId);
        }
        if (languageId) {
            url = url + "?languageId=" + languageId;
        }

        this.get(url).then((response) => {
            if (screenId === 'start') {
                store.state.firstScreenId = response.data.screen.id;
                this.get(defaultLanguageAPI).then(function (response) {
                    store.state.defaultLanguage = response.data;
                    store.commit('selectLanguage', response.data.id);
                }).catch(function (er) {
                    console.log(er);
                })
            }
            store.commit("updateScreen", response.data);
            router.push({name: response.data.screen.type});
            currentStep.id = response.data.screen.id;
            app.resetStepData();
            store.state.disableButtons = false;
        }).catch((er) => {
            console.log(er);
            store.state.disableButtons = false;
            store.commit('showError', true);
            if (er.response) {
                store.state.errorStatus = er.response.status;
            }
        });
    },

    getStartScreen() {
        store.state.currentSiteId = localStorage.getItem("qppSiteId");
        this.getScreen('start');
    },

    goToNextScreen(screenshot, startNow = false) {
        let lang = store.state.languageId;
        let visitor = store.state.visitor;
        let qrCode = store.state.qrCode;
        let url = nextAPI;
        let flowId = screenshot.selectedButton.id;
        let _screenId = screenshot.screenProperties.id;
        let app = this;
        let payload = {
            "informationFlowId": flowId,
            "domainTypeId": screenshot.domaintypeId
        };

        if (screenshot.checkEid) {
            payload['checkEid'] = screenshot.checkEid
        }

        if (screenshot.checkEer) {
            payload['checkEer'] = screenshot.checkEer
        }

        if (JSON.stringify(visitor) !== '{}') {
            payload.visitor = visitor;
            console.log(visitor);
        }

        if (JSON.stringify(qrCode) !== '{}') {
            payload.qrCode = qrCode;
            console.log(qrCode);
        }
        if (lang) {
            url = url + "?languageId=" + lang;
        }
        this.post(url, payload, {screenId: _screenId}).then(function (response) {
            store.commit("updateScreen", response.data);
            if (startNow === true) {
                currentStep.startTime = Date.now();
            }
            if(response.data.screen.notificationText){
                let audio = new Audio(timerSound);
                audio.play();
                store.commit('showTimerPopup', true);
                store.commit('setTimerPopupMessage', response.data.screen.notificationText);
            }
            router.push({name: response.data.screen.type});
            app.setStepData(Date.now(), screenshot);
            store.commit('addNextScreen', currentStep);
            if(response.data.instructions!=null && response.data.instructions.domainTypeId!=null){
                store.commit('setDomain', response.data.instructions.domainTypeId);
            }
            currentStep.id = response.data.screen.id;
            app.resetStepData();
            store.state.disableButtons = false;
        }).catch(function (er) {
            console.log(er)
            app.setStepData(Date.now(), screenshot);
            currentStep.error = er.response ? er.response.data : er
            store.commit('addNextScreenToAllSteps', currentStep);
            store.state.disableButtons = false;
            if (er.response) {
                store.state.errorStatus = er.response.status;
                if(er.response.status === 426) {
                    console.log(er.response)
                    store.state.qrCodeError = true;
                }
                else {
                    store.commit('showError', true);
                }
            }
        });
    },

    goToPreviousScreen(screenshot) {
        let app = this;
        store.dispatch('getPreviousScreen').then(function (previousScreenId) {
            app.setStepData(Date.now(), screenshot);
            store.commit('addNextScreenToAllSteps', currentStep);
            store.commit('setDomain', null);
            store.commit('setqrCodeData', {});
            app.getScreen(previousScreenId, store.state.languageId);
        });
    },

    getTranslation(lang, key) {
        let url = translationAPI + lang + "/" + key;
        return this.get(url);
    },

    setStepData(date, screenshot) {
        currentStep.endTime = date;
        if (screenshot) {
            currentStep.selectedOption = screenshot.selectedButton.id;
            currentStep.languageId = screenshot.languageId ?? store.state.languageId;
            currentStep.domaintypeId = screenshot.domaintypeId ?? store.state.domaintypeId;
            currentStep.flowOptions = screenshot.flowOptions
            currentStep.screenProperties = screenshot.screenProperties ?? store.state.currentScreenProperties;
            currentStep.instructions = screenshot.instructions
        }
    },

    resetStepData() {
        currentStep.startTime = Date.now();
        currentStep.endTime = Date.now();
        currentStep.selectedOption = null;
        currentStep.languageId = null;
        currentStep.domaintypeId = null;
        currentStep.flowOptions = [];
        currentStep.screenProperties = {};
        currentStep.instructions = {};
    },

    showPopup() {
        // console.log(store.state.currentScreenProperties.type + ' timeout period is : ' + store.state.currentScreenProperties.timeOut);

        this.resetIdleTimeoutForPopup();

        boundReset = this.resetIdleTimeoutForPopup.bind(this);
        ['click', 'touchstart'].forEach(evt => {
                document.addEventListener(evt, boundReset, false);
            }
        );
    },

    resetIdleTimeoutForPopup() {
        let timeInSec = store.state.currentScreenProperties.timeOut;

        if (idleTimeoutPopup) clearTimeout(idleTimeoutPopup);

        idleTimeoutPopup = setTimeout(() => {
            store.commit('showModal', true);
            this.countDownToHome();
        }, timeInSec * 1000);
    },

    countDownToHome: function () {
        console.log("timeout");
        let idleTimeoutForCountdown;

        // Clears the existing timeout
        if (idleTimeoutForCountdown) clearTimeout(idleTimeoutForCountdown);

        // Set a new idle timeout to load the redirectUrl after idleDurationSecs
        idleTimeoutForCountdown = setTimeout(() => {
            this.resetFlow({selectedButton: {id: 'automaticRedirect'}});
        }, idleDurationForCountdown * 1000);

        ['click', 'touchstart'].forEach((evt) => {
                document.addEventListener(evt, () => {
                    if (idleTimeoutForCountdown) clearTimeout(idleTimeoutForCountdown)
                }, false);

            }
        );
    },

    resetPopup() {
        ['click', 'touchstart'].forEach(evt =>
            document.removeEventListener(evt, boundReset, false)
        );
        if (idleTimeoutPopup) clearTimeout(idleTimeoutPopup);
    },


    // API functions

    get(endPoint, pathParams = {}) {
        let path = this._format(endPoint, pathParams);

        return axios.get(path, this._getHeaders());
    },

    post(endPoint, data = {}, pathParams = {}) {
        let path = this._format(endPoint, pathParams);

        return axios.post(path, data, this._getHeaders());
    },

    _getHeaders() {
        store.state.accessToken = localStorage.getItem("qppAccessToken")
        let accessToken = store.state.accessToken;
        let headers = {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            withCredentials: true
        }

        return headers
    },

    _format(endPoint, pathParams = {}) {
        if (endPoint.includes(':siteId')) {
            pathParams['siteId'] = store.state.currentSiteId;
        }

        for (const key in pathParams) {
            const value = pathParams[key];
            endPoint = endPoint.replaceAll(":" + key, value);
        }

        if (!endPoint.startsWith("/")) {
            endPoint = "/" + endPoint;
        }

        return baseURL + endPoint;
    }
};