<template>
  <transition name="modal">
    <div class="modal-mask" id="error-modal">
      <div class="modal-wrapper">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title vdab-orange"> {{ popupCardWarning }}</h2>
            </div>
            <div class="modal-body">
              <input type="button" class="btn blue-btn btn-success btn-xs" :value="popupWarningButton" @click="close"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from "@/store";
import screenService from "@/services/screenApiService";
// import {mapState} from "vuex";

export default {
  name: "CardWarning",
  data() {
    return {
      popupCardWarning: '',
      popupWarningButton: ''
    }
  },
  emits: ['closeWarning'],
  mounted() {
    this.getTranslation(store.state.languageId, 'popupCardWarning');
    this.getTranslation(store.state.languageId, 'popupWarningButton');
  },
  methods: {
    getTranslation(lang = store.state.languageId, key) {
      var app = this;
      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function (error) {
          console.log("No translation found for fe." + key + " in language " + lang);
          console.log(error);
          app[key] ="-";
          //app.getTranslation(store.state.defaultLanguage.id, key);
        })
      }
    },
    close() {
      this.$emit('closeWarning')
    }
  }

}
</script>