<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import layout from "simple-keyboard-layouts/build/layouts/french";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    }
  },
  data: () => ({
    keyboard: null
  }),
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      theme: "hg-theme-default hg-layout-default myTheme",
      ...layout,
      // buttonTheme: [
      //   {
      //     class: "hg-highlight",
      //     buttons: "{enter}"
      //   }
      // ],
    });
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    }
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    }
  }
};
</script>


<style>
.simple-keyboard {
  color: var(--vdab-darkblue-color);
}

.simple-keyboard.hg-theme-default.myTheme {
  border: 3px solid var(--vdab-lightblue-color);
  border-radius: 10px;
  border-radius: 10px;
  font-family: var(--flow-font-family);
  /*background: rgba(45, 137, 204, 0.5);*/
}

.simple-keyboard.hg-layout-default .hg-button.hg-highlight {
  box-shadow: 0px 0px 20px 5px rgb(231, 97, 2);
  background: var(--vdab-darkblue-color);
  color: white;
  z-index: 1;
}

.hg-theme-default .hg-button {
  height: 4.5vh!important;
}

.hg-theme-default .hg-button span {
  font-size: 30px;
}
</style>
