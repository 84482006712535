<template>
  <div v-if="currentRouteMeta.footer === false">
    <img src="@/assets/images/FlemishLion.png" class="footer_lion" alt="lion"/>
    <img src="@/assets/images/VDAB_logo_donkerblauw_RGB.jpg" class="footer_vdab" alt="VDAB"/>
  </div>
  <div class="footer-buttons" v-else>
    <div class="f-btn">
      <div class="w-2 center">
        <i class="fa fa-5x fa-chevron-circle-left" title="Terug" @click="goBack({'id':'back'})"/><br>
        <span>{{ back }}</span>
      </div>
    </div>
    <div class="f-btn">
      <div class="center right">
        <i class="fa fa-5x fa-home" title="Home" @click="resetFlow({'id':'home'})"/><br>
        <span>{{ home }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import screenService from "@/services/screenApiService";
import store from "@/store";
import {mapState} from "vuex";

export default {
  name: "Footer",
  computed: {
    currentRouteMeta() {
      return this.$route.meta;
    },
    ...mapState(['languageId'])
  },
  data() {
    return {
      back: '',
      home: ''
    }
  },
  watch: {
    languageId(newValue) {
      this.getTranslation(newValue, 'home');
      this.getTranslation(newValue, 'back');
    }
  },
  mounted() {
    if (this.languageId !== null) {
      this.getTranslation(this.languageId, 'home');
      this.getTranslation(this.languageId, 'back');
    }
  },
  methods: {
    getTranslation(lang = store.state.languageId, key) {
      var app = this;
      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function () {
          console.log("No translation found for fe." + key + " in language " + lang);
        })
      }
    },
    goBack(button) {
      let screenshot = {
        selectedButton: button,
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        instructions: store.state.currentScreenInstructions,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId,
      };
      screenService.goToPreviousScreen(screenshot);
    },
    resetFlow(button) {
      let screenshot = {
        selectedButton: button,
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        instructions: store.state.currentScreenInstructions,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId,
      };
      screenService.resetFlow(screenshot);
    },
  }
}
</script>