<template>
  <div v-if="isInstruction">
    <span v-if="toggleText" :class="instructions.walkingInstructionHelp.length > 200 ? 'long-instruction' : ''" v-html="instructions.walkingInstructionHelp"></span>
    <span v-else :class="instructions.walkingInstruction.length > 200 ? 'long-instruction' : ''" v-html="instructions.walkingInstruction"></span>
  </div>
  <div v-else>
    <span v-if="toggleText" v-html="screenProperties.helpText"></span>
    <span v-else v-html="screenProperties.text"></span>
  </div>
</template>

<style scoped>
	.long-instruction {
		font-size: 1.4vh!important;
		letter-spacing: 1px!important;
	}
</style>

<script>
import {mapState} from "vuex";

export default {
  name: "DisplayText",
  props: ['screenProperties', 'isInstruction', 'instructions'],
  computed: mapState(['toggleText'])
}
</script>